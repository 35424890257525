body {
    background-color: wheat;
    text-align: center;
}

.container {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr;
}

#generate {
    height: 3em;
    width: 45%;
    font-size: 1em;
    padding: 0;
}

#copy {
    width: 5%;
    margin: 0;
    padding: 0;
    height: 3em;
    width: 3em;
}


img {
    height: 20px;
    width: 20px;
}

li {
    list-style-type: none;
}

ul {
    padding-left: 0;
    /* height: 50%;
    overflow: hidden;
    overflow-y: scroll; */
}
